<template>
  <div>
    <div
      class="sidebar_nav_section"
      style="width: 10rem; overflow: auto;" :style="{height: height + 'px'}"
    >
      <div>
        <!--   This section should be at the top -->
        <div class="section_nav_section">
          <ul v-if="true" class="left_mnemo h-full coreMenu">
            <li v-if="(selectedType == 'witsml' || selectedType == 'wits0')">
              <button
                v-on:click="toggle_section('open_runs')"
                :class="[
                  'dropdownToggleBtn',
                  'text-left',
                  'w-full',
                  'td_btn',
                  'flex',
                ]"
              >
                Runs
                <span v-if="open_runs"><i class="fas fa-angle-up"></i></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-if="open_runs" class="px-2">
                <div
                  class="color flex w-full items-center mt-2 fontSize"
                  v-for="(lwdId, index) in lwd_ids"
                  :key="index"
                  :title="lwdId.name"
                >
                  <label
                    :for="index"
                    class="runLables uppercase w-full overflow-ellipsis overflow-hidden"
                    >{{ lwdId.name }}</label
                  >
                  <input
                    :id="index"
                    :value="lwdId"
                    v-model="selectedRuns"
                    type="radio"
                    class="checkbox_optimization"
                    @click="reselect_run(lwdId)"
                  />
                </div>
                <div
                  v-if="lwd_ids.length && lwd_ids.length > 1"
                  style="border-top: 1px solid currentColor; padding-top: 7px"
                  class="color flex w-full items-center mt-2 fontSize"
                >
                  <label for="consolidated_all" class="uppercase w-full overflow-ellipsis overflow-hidden"
                    >Consolidated</label
                  >
                  <input
                    id="consolidated_all"
                    v-model="selectedRuns"
                    value="all"
                    type="radio"
                    class="checkbox_optimization"
                    @click="reselect_run('all')"
                  />
                </div>
              </div>
            </li>
            <li v-if="selectedType == 'las'">
              <button
                v-on:click="toggle_section('open_lwd_runs')"
                :class="[
                  'dropdownToggleBtn',
                  'text-left',
                  'w-full',
                  'td_btn',
                  'flex',
                ]"
              >
                LWD Runs
                <span v-if="open_lwd_runs"><i class="fas fa-angle-up"></i></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-if="open_lwd_runs" class="px-2">
                <div
                  class="color flex w-full items-center mt-2 fontSize"
                  v-for="(lwdId, index) in lwd_lwdlog_ids"
                  :key="index"
                  :title="lwdId.name"
                >
                  <label
                    :for="index"
                    class="runLables uppercase w-full overflow-ellipsis overflow-hidden"
                    >{{ lwdId.name }}</label
                  >
                  <input
                    :id="index"
                    :value="lwdId"
                    v-model="selectedRuns"
                    type="radio"
                    class="checkbox_optimization"
                    @click="reselect_run(lwdId)"
                  />
                </div>
                <div
                  v-if="lwd_lwdlog_ids.length && lwd_lwdlog_ids.length > 1"
                  style="border-top: 1px solid currentColor; padding-top: 7px"
                  class="color flex w-full items-center mt-2 fontSize"
                >
                  <label for="lwd_consolidated_all" class="uppercase w-full overflow-ellipsis overflow-hidden"
                    >Consolidated</label
                  >
                  <input
                    id="lwd_consolidated_all"
                    v-model="selectedRuns"
                    value="lwdlog_all"
                    type="radio"
                    class="checkbox_optimization"
                    @click="reselect_run('lwdlog_all')"
                  />
                </div>
              </div>
            </li>
            <li v-if="selectedType == 'las'">
              <button
                v-on:click="toggle_section('open_wireline_runs')"
                :class="[
                  'dropdownToggleBtn',
                  'text-left',
                  'w-full',
                  'td_btn',
                  'flex',
                ]"
              >
                Wireline Runs
                <span v-if="open_wireline_runs"><i class="fas fa-angle-up"></i></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-if="open_wireline_runs" class="px-2">
                <div
                  class="color flex w-full items-center mt-2 fontSize"
                  v-for="(lwdId, index) in lwd_wirelinelog_ids"
                  :key="index"
                  :title="lwdId.name"
                >
                  <label
                    :for="index"
                    class="runLables uppercase w-full overflow-ellipsis overflow-hidden"
                    >{{ lwdId.name }}</label
                  >
                  <input
                    :id="index"
                    :value="lwdId"
                    v-model="selectedRuns"
                    type="radio"
                    class="checkbox_optimization"
                    @click="reselect_run(lwdId)"
                  />
                </div>
                <div
                  v-if="lwd_wirelinelog_ids.length && lwd_wirelinelog_ids.length > 1"
                  style="border-top: 1px solid currentColor; padding-top: 7px"
                  class="color flex w-full items-center mt-2 fontSize"
                >
                  <label for="wireline_consolidated_all" class="uppercase w-full overflow-ellipsis overflow-hidden"
                    >Consolidated</label
                  >
                  <input
                    id="wireline_consolidated_all"
                    v-model="selectedRuns"
                    value="wirelinelog_all"
                    type="radio"
                    class="checkbox_optimization"
                    @click="reselect_run('wirelinelog_all')"
                  />
                </div>
              </div>
            </li>
            <li>
              <button
                v-on:click="toggle_section('open_map')"
                :class="[
                  'dropdownToggleBtn',
                  'text-left',
                  'w-full',

                  'td_btn',
                  'flex',
                ]"
              >
                Units
                <span v-if="open_map"><i class="fas fa-angle-up"></i></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-if="open_map" class="px-2">
                <div class="flex pb-2 items-start flex-col">
                  <p class="label_heading fontSize">Units</p>
                  <select
                    @change="change_unit($event)"
                    v-model="selected_unit"
                    class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none"
                  >
                    <option value="" disabled>Select</option>
                    <option
                      v-for="option in unit_options"
                      :value="option.value"
                      :key="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="flex pb-2 items-start flex-col">
                  <p class="label_heading fontSize">Log Scale</p>
                  <select
                    @change="change_log_scale($event)"
                    v-model="logScale"
                    class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none"
                  >
                    <option value="" disabled>Select</option>
                    <option
                      v-show="selected_unit == 'METRIC'"
                      v-for="option of metric_options"
                      :value="option"
                      :key="option.value"
                    >
                      {{ option }}
                    </option>
                    <option
                      v-show="selected_unit == 'IMPERIAL'"
                      v-for="option of imperial_options"
                      :value="option"
                      :key="option.value"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </li>
            <li>
              <button
                v-on:click="toggle_section('open_wellList')"
                :class="[
                  'dropdownToggleBtn',
                  'text-left',
                  'w-full',

                  'td_btn',
                  'flex',
                ]"
              >
                Depth type
                <span v-if="open_wellList"
                  ><i class="fas fa-angle-up"></i
                ></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-if="open_wellList" class="px-2">
                <div class="color flex w-full items-center mt-2 fontSize">
                  MD
                  <input
                    value="MD"
                    v-model="depth_type"
                    @click="click_depth_type($event)"
                    type="radio"
                    name="optimization"
                    class="checkbox_optimization"
                  />
                </div>
                <div class="color flex w-full items-center mt-2 fontSize" v-if="show_tvd_radio_btn">
                  TVD
                  <input
                    value="TVD"
                    v-model="depth_type"
                    @click="click_depth_type($event)"
                    type="radio"
                    name="optimization"
                    class="checkbox_optimization"
                  />
                </div>
              </div>
            </li>
            <li>
              <button
                v-on:click="toggle_section('open_Optmization')"
                :class="[
                  'dropdownToggleBtn',
                  'text-left',
                  'w-full',

                  'td_btn',
                  'flex',
                ]"
              >
                Number of Tracks
                <span v-if="open_Optmization"
                  ><i class="fas fa-angle-up"></i
                ></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-if="open_Optmization" class="px-2">
                <div class="flex pb-2 items-start flex-col">
                  <p class="label_heading fontSize">Number of Tracks</p>
                  <select
                    @change="change_numberof_tracks($event)"
                    v-model="numberOfTracks"
                    class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none"
                  >
                    <option value="" disabled>Select</option>
                    <option v-for="number in 5" :key="number" :value="number">
                      {{ number }}
                    </option>
                  </select>
                </div>
              </div>
            </li>
            <li v-for="(recrd, index) of tracksData" :key="index">
              <button
                v-on:click="toggle_section(index + 1, 'track')"
                :class="[
                  'dropdownToggleBtn',
                  'text-left',
                  'w-full',

                  'td_btn',
                  'flex',
                ]"
              >
                Track {{ index + 1 }}
                <span v-if="clicked_tracks.includes(index + 1)"
                  ><i class="fas fa-angle-up"></i
                ></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-show="clicked_tracks.includes(index + 1)" class="px-2">
                <div class="flex pb-2 items-start flex-col">
                  <p class="label_heading fontSize">Track Scale</p>
                  <select
                    @change="trackScaleChange(recrd)"
                    v-model="recrd.trackScale"
                    class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none"
                  >
                    <option value="" disabled>Select</option>
                    <option value="linear">Linear</option>
                    <option value="logarithmic">Logarithmic</option>
                  </select>
                </div>
                <div
                  class="flex pb-2 items-start flex-col"
                  v-show="recrd.trackScale == 'logarithmic'"
                >
                  <p class="label_heading fontSize">Logarithmic Cycle Scale</p>
                  <select
                    @change="trackLogarithmicCycleScaleChange(recrd)"
                    v-model="recrd.logarithmicCycleScale"
                    class="fields focus:ring-indigo-500 h-9 border-0 block w-5/12 left-0 font-color leading-none"
                  >
                    <option value="" disabled>Select</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                <div class="flex pb-2 items-start flex-col">
                  <p class="label_heading fontSize">Sub Tracks</p>
                  <multiSelect
                    :reqOptions="multiSelectOptions"
                    :selectedOptions="recrd.subtracks"
                    :recrdIndex="recrd.index"
                    @selectOption="selectOption"
                  />
                </div>
              </div>
            </li>
            <li class="flex flex-col items-center mt-2" v-if="selectedRuns == 'all' || Object.keys(selectedRuns).length">
               <button class="bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none 
                  focus:outline-none ease-linear transition-all duration-150"
                @click="$emit('save_default_new_file')">
                Save as default
               </button>
              <Teleport :to="'#newFileSaveBtn'+widgetId">
                <!-- <button class="bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none 
                    focus:outline-none ease-linear transition-all duration-150"
                  @click="$emit('show_file_save_popup')"> -->
                <button class="mx-2 fontSize buttonStyles"
                  @click="$emit('show_file_save_popup')">
                  Save
                </button>
              </Teleport>
            </li>
            <li v-if="savedLwdList.length > 0 && (savedLwdList.length == 1 ? !savedLwdList[0]?.is_default : true)">
              <button v-on:click="toggle_section('savedList')" :class="['dropdownToggleBtn',
                'text-left',
                'w-full',

                'td_btn',
                'flex',
              ]">
                Saved List
                <span v-if="savedList"><i class="fas fa-angle-up"></i></span>
                <span v-else><i class="fas fa-angle-down"></i></span>
              </button>
              <div v-if="savedList" class="px-2">
                <div class="color flex w-full items-center mt-2 fontSize" v-for="(savedLwdId, index) in savedLwdList" :key="index"
                  :title="savedLwdId.name" v-show="!savedLwdId?.is_default" @dblclick="$emit('showSavedFileDeletePopup', savedLwdId)">
                  <div class="flex w-full" @change="click_selectedSavedLwdId($event)">
                    <label :for="index" class="runLables w-full overflow-ellipsis overflow-hidden">{{ savedLwdId.name
                    }}</label>
                    <input :id="index" :value="savedLwdId" v-model="selectedSavedLwdId" type="radio" class="checkbox_optimization" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import multiSelect from "../Widgets/Lwd/LwdMultiSelect.vue";

export default {
  name: "LWD-sidebar",
  emits: ['selected_runs', 'depthType_toggle_listener', 'selcetedFileForSave', 'show_file_save_popup', 'changeScaleInTrend',
   'save_default_new_file', 'showSavedFileDeletePopup', 'setting_type_of_consolidated', 'apply_rerender'],
  data() {
    return {
      selected_unit: "",
      logScale: "",
      unit_options: [
        { text: "METRIC UNIT", value: "METRIC" },
        { text: "IMPERIAL UNIT", value: "IMPERIAL" },
      ],
      metric_options: ["1:200", "1:500"],
      imperial_options: ["1:1200", "1:600", "1:240"],
      depth_type: "",
      numberOfTracks: null,
      tracksData: [],
      clicked_tracks: [],
      multiSelectOptions: {},
      open_map: false,
      open_wellList: false,
      open_Optmization: false,
      savedList: false,
      after_deleting_in_trend: [],
      open_runs: true,
      open_lwd_runs: true,
      open_wireline_runs: false,
      lwd_ids: [],
      lwd_lwdlog_ids: [],
      lwd_wirelinelog_ids: [],
      selectedRuns: {},
      selectAllRuns: false,
      show_tvd_radio_btn: true,
      selectedSavedLwdId: {},
    };
  },
  props: {
    displayId: String,
    widgetId: String,
    widgetIndex: [Number, String],
    displayIndex: [Number, String],
    idx: [Number],
    mnemonics_data: [Array],
    mnemonics_consolidated_data: [Array, String],
    copy_of_data_points: [Object, Array],
    selectedType: String,
    savedLwdList: Array,
    select_default_saved_file: Boolean,
    newly_saved_or_updated_file_payload: Object,
    height: [Number, String]
  },
  components: {
    multiSelect,
  },
  async mounted() {
    this.selected_unit = "IMPERIAL";
    await this.commonStoreDispatch("selected_unit");
    this.logScale = "1:600";
    await this.change_log_scale();
    this.numberOfTracks = 4;
    await this.change_numberof_tracks();
    this.depth_type = "MD";
  },
  methods: {
    async reselect_run(val){
      if(JSON.stringify(val) == JSON.stringify(this.selectedRuns)){
        this.selectedRuns = val;
        // await this.selected_runs_common_fun(val);
        this.selectedSavedLwdId = {};
        if(this.savedLwdList.length){
          let defaultObj = this.savedLwdList.find((each)=> each.is_default == true)
          if(defaultObj){
            this.selectedSavedLwdId = defaultObj
          }
        }
        await this.$emit('apply_rerender');
      }
    },
    async selected_runs_common_fun(val){
      if(this.selectedType != 'witsml' && this.selectedType != 'wits0'){
        this.empty_tracks_data();
      }
      if (val == 'all' || val == 'lwdlog_all' || val == 'wirelinelog_all') {
        let options_to_show = {};
        this.mnemonics_data.map(each=>{
          if(val == 'wirelinelog_all' && each.solution_type.replace(/\s/g,'').includes('WIRELINELOG')){
            options_to_show = {...options_to_show, ...JSON.parse(each.pnrg_mapping_data)}
          }
          else if(val == 'lwdlog_all' && each.solution_type.replace(/\s/g,'').includes('LWDLOG')){
            options_to_show = {...options_to_show, ...JSON.parse(each.pnrg_mapping_data)}
          }
        })
        // this.multiSelectOptions = JSON.parse(this.mnemonics_consolidated_data);
        this.multiSelectOptions = options_to_show;
        if (Object.keys(this.multiSelectOptions).includes("DEPTH")) {
          delete this.multiSelectOptions?.DEPTH;
        }
        if (Object.keys(this.multiSelectOptions).includes('TVD')) {
          this.show_tvd_radio_btn = true;
          delete this.multiSelectOptions?.TVD
        }
        else{
          this.depth_type = 'MD'
          this.show_tvd_radio_btn = false;
        }
        if(val == 'all'){
          await this.$emit('setting_type_of_consolidated', null);
          await this.$emit('selected_runs', [...this.lwd_ids], '', 'selectDefault', 'fromSidebar')
        }
        else if(val == 'lwdlog_all'){
          await this.$emit('setting_type_of_consolidated', 'lwdlog_consolidated');
          await this.$emit('selected_runs', [...this.lwd_lwdlog_ids], '', 'selectDefault', 'fromSidebar')
        }
        else if(val == 'wirelinelog_all'){
          await this.$emit('setting_type_of_consolidated', 'wirelinelog_consolidated');
          await this.$emit('selected_runs', [...this.lwd_wirelinelog_ids], '', 'selectDefault', 'fromSidebar')
        }
      }
      else {
        await this.$emit('setting_type_of_consolidated', null);
        if (Object.keys(val).length) {
          let obj = this.mnemonics_data.find((file) => {
            return (
              file.lwd_data_id == val.id &&
              file.alias_name == val.name &&
              file.start_depth == val.start_depth &&
              file.end_depth == val.end_depth
            );
          });
          if (obj) {
            this.multiSelectOptions = JSON.parse(obj?.pnrg_mapping_data);
            if (Object.keys(this.multiSelectOptions).includes("DEPTH")) {
              delete this.multiSelectOptions?.DEPTH;
            }
            if (Object.keys(this.multiSelectOptions).includes('TVD')) {
              this.show_tvd_radio_btn = true;
              delete this.multiSelectOptions?.TVD
            }
            else{
              this.depth_type = 'MD'
              this.show_tvd_radio_btn = false;
            }
          }
          else {
            this.multiSelectOptions = {};
          }
          await this.$emit("selected_runs", [val], '', 'selectDefault', 'fromSidebar');
        }
      }
    },
    async click_selectedSavedLwdId(event){
      await this.$emit('apply_rerender');
    },
    async click_depth_type(){
      await this.$emit('apply_rerender');
    },
    empty_tracks_data() {
      this.tracksData = [];
      for (let trackNum = 0; trackNum < this.numberOfTracks; trackNum++) {
        this.tracksData.push({
          index: trackNum,
          trackScale: trackNum == 1 ? "logarithmic" : "linear",
          logarithmicCycleScale: trackNum == 1 ? 4 : "",
          subtracks: [],
        });
      }
    },
    toggle_section(model, from) {
      let vars = ['open_runs', 'open_lwd_runs', 'open_wireline_runs', 'open_map', 'open_wellList', 'open_Optmization', 'savedList']
      if (from == 'track') {
        vars.map(each => each == model && !this[each] ? this[each] = true : this[each] = false)
        if (this.clicked_tracks.length && this.clicked_tracks[0] == model) {
          this.clicked_tracks = [];
        } else {
          this.clicked_tracks = [model];
        }
      } else {
        vars.map((each) =>
          each == model && !this[each]
            ? (this[each] = true)
            : (this[each] = false)
        );
        this.clicked_tracks = [];
      }
    },
    async change_numberof_tracks(event) {
      await this.commonStoreDispatch("numberOfTracks");
      if (this.tracksData?.length) {
        if (this.tracksData.length < this.numberOfTracks) {
          let appendLength = this.numberOfTracks - this.tracksData.length;
          for (let emptyTrack = 0; emptyTrack < appendLength; emptyTrack++) {
            this.tracksData.push({
              index: this.tracksData.length,
              trackScale:
                this.tracksData.length == 1 ? "logarithmic" : "linear",
              logarithmicCycleScale: this.tracksData.length == 1 ? 4 : "",
              subtracks: [],
            });
          }
        } else if (this.tracksData.length > this.numberOfTracks) {
          let removeTracks = this.tracksData.length - this.numberOfTracks;
          this.tracksData.splice(
            this.tracksData.length - removeTracks,
            removeTracks
          );
        }
      } else {
        this.empty_tracks_data();
      }
      if(event){
        await this.$emit('apply_rerender')
      }
    },
    toggle_tracks(number) {
      if (this.clicked_tracks.includes(number)) {
        this.clicked_tracks = this.clicked_tracks.filter(
          (each) => each != number
        );
      } else {
        this.clicked_tracks.push(number);
      }
    },
    async selectOption(index, item) {
      let hadRecord = this.tracksData[index]?.subtracks.some(
        (each) => each.sourceMnemonic == item.sourceMnemonic
      );
      if (hadRecord) {
        let indx = this.tracksData[index]?.subtracks.findIndex(
          (each) => each.sourceMnemonic == item.sourceMnemonic
        );
        this.tracksData[index]?.subtracks.splice(indx, 1);
      } else {
        if (this.tracksData[index]?.subtracks.length == 5) {
          this.$toast.clear();
          this.$toast.error(`Can select upto 5 subtracks only`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        } else {
          if(this.selectedSavedLwdId != null && Object.keys(this.selectedSavedLwdId).length){
            let hadInSaved = this.selectedSavedLwdId?.content?.tracksData[index]?.subtracks.find((ee)=> ee.sourceMnemonic == item.sourceMnemonic)
            if(hadInSaved){
              this.tracksData[index]?.subtracks.push(hadInSaved)
            }
            else{
              this.tracksData[index]?.subtracks.push(item);
            }
          }
          else{
            this.tracksData[index]?.subtracks.push(item);
          }
        }
      }
      await this.$emit('apply_rerender');
    },
    toggle_height_handler() {
      this.open_map = !this.open_map;
    },
    toggle_height_runs() {
      this.open_runs = !this.open_runs;
    },
    toggle_handler_wellList() {
      this.open_wellList = !this.open_wellList;
    },
    toggle_optimization_handler() {
      this.open_Optmization = !this.open_Optmization;
    },
    async change_unit(event) {
      this.logScale = "";
      await this.commonStoreDispatch("selected_unit");
      if (this.selected_unit == "METRIC") {
        this.logScale = this.metric_options[0];
        await this.commonStoreDispatch("logScale");
        if(event){
          await this.$emit('apply_rerender')
        }
      } else if (this.selected_unit == "IMPERIAL") {
        this.logScale = this.imperial_options[0];
        await this.commonStoreDispatch("logScale");
        if(event){
          await this.$emit('apply_rerender')
        }
      }
    },
    async change_log_scale(event) {
      await this.commonStoreDispatch("logScale");
      if(event){
        await this.$emit('apply_rerender')
      }
    },
    async commonStoreDispatch(vModel) {
      this.$store.dispatch("rect/LwdData", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: {
          ...this.$store.state.rect.rects[this.idx]?.lwdLeftBarData,
          [vModel]: this[vModel],
        },
        field: "lwdLeftBarData",
      });
    },
    async trackScaleChange(recrd) {
      recrd.trackScale != "logarithmic"
        ? (recrd.logarithmicCycleScale = "")
        : recrd.logarithmicCycleScale = 2;
        await this.$emit('apply_rerender');
    },
    async trackLogarithmicCycleScaleChange(recrd){
      await this.$emit('apply_rerender');
    }
  },
  watch: {
    async selectedSavedLwdId(value, oldVal){
      if(JSON.stringify(value) == JSON.stringify(oldVal)){
        return;
      }
      let val = JSON.parse(JSON.stringify(value));
      if(val !== null && Object.keys(val).length){
        this.selected_unit = val.content.selected_unit;
        await this.commonStoreDispatch("selected_unit")
        this.logScale = val.content.logScale;
        await this.change_log_scale();
        this.depth_type = val.content.depth_type
        this.numberOfTracks = val.content.numberOfTracks;
        await this.$emit('changeScaleInTrend', val)
        this.tracksData = val.content.tracksData;
        await this.change_numberof_tracks();
      }
      else{
        await this.$emit('changeScaleInTrend', val)
        await this.empty_tracks_data();
      }
    },
    savedLwdList(val){
      if(val.length){
        if(Object.keys(this.newly_saved_or_updated_file_payload).length){
          let findObj = val.find((each)=> each.name == this.newly_saved_or_updated_file_payload.name)
          if(findObj){
            this.selectedSavedLwdId = findObj;
          }
          else{
            let defaultObj = val.find((each)=> each.is_default == true)
            if(defaultObj && this.select_default_saved_file){
              this.selectedSavedLwdId = defaultObj
            }
            else{
              this.selectedSavedLwdId = {};
            }
          }
        }
        else{
          let defaultObj = val.find((each)=> each.is_default == true)
          if(defaultObj && this.select_default_saved_file){
            this.selectedSavedLwdId = defaultObj
          }
          else{
            this.selectedSavedLwdId = {};
          }
        }
      }
      else{
        this.selectedSavedLwdId = {};
      }
    },
    selectedType(val) {
      this.empty_tracks_data();
    },
    async selectAllRuns(val) {
      if (val) {
        // this.selectedRuns = [...this.lwd_ids]
        await this.$emit("selected_runs", [...this.lwd_ids]);
      }
      // else {
      //   this.selectedRuns = []
      // }
    },
    async depth_type(val) {
      await this.$emit("depthType_toggle_listener", val);
      if (
        val == "TVD" &&
        !Object.keys(this.copy_of_data_points).includes("TVD")
      ) {
        this.$toast.clear();
        this.$toast.error(`TVD data not available`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
      await this.commonStoreDispatch("depth_type");
    },
    async tracksData(val) {
      await this.commonStoreDispatch("tracksData");
    },
    async selectedRuns(val) {
      // if (this.selectAllRuns && val.length != this.lwd_ids.length) {
      //   this.selectAllRuns = false
      // }
      // else {
      //   await this.$emit('selected_runs', [val])
      // }
      // this.selectedSavedLwdId = {};
      await this.selected_runs_common_fun(val);
    },
    mnemonics_data(val) {
      this.lwd_ids = [];
      this.lwd_lwdlog_ids = [];
      this.lwd_wirelinelog_ids = [];
      if (this.selectedType != "witsml" && this.selectedType != "wits0") {
        this.selectedRuns = {};
      }
      if (val && val != "") {
        val.map((each) => {
          this.lwd_ids.push({
            id: each?.lwd_data_id,
            start_depth: each.start_depth,
            end_depth: each.end_depth,
            name: each.alias_name,
          });
          if(each.solution_type.replace(/\s/g,'').includes('WIRELINELOG')){
            this.lwd_wirelinelog_ids.push({
              id: each?.lwd_data_id,
              start_depth: each.start_depth,
              end_depth: each.end_depth,
              name: each.alias_name,
            });
          }
          else if(each.solution_type.replace(/\s/g,'').includes('LWDLOG')){
            this.lwd_lwdlog_ids.push({
              id: each?.lwd_data_id,
              start_depth: each.start_depth,
              end_depth: each.end_depth,
              name: each.alias_name,
            });
          }
        });
        if (this.selectedType == "witsml" || this.selectedType == "wits0") {
          this.selectedRuns = this.lwd_ids[0];
        }
      }
      else {
        if(this.selectedType == "witsml" || this.selectedType == "wits0") { // TO EMPTY TRACKS FOR WITSML AND WITS0, AFTER WELL SELECTION
          this.empty_tracks_data();
          this.selectedRuns = {};
        }
        this.multiSelectOptions = {};
      }
    },
    async after_deleting_in_trend(val) {
      this.tracksData = val;
    },
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    tracksData() {
      this.after_deleting_in_trend =
        this.$store.state.rect.rects[this.idx]?.lwdLeftBarData?.tracksData;
      return this.$store.state.rect.rects[this.idx]?.lwdLeftBarData?.tracksData;
    },
  },
};
</script>
<style scoped >
.color {
  color: var(--colorBoxHeadingText);
}

.checkbox_optimization:checked {
  background-color: black !important;
}

.label_heading {
  /* font-weight: 400 !important; */
  font-size: 11px;
  text-transform: uppercase;
  color: var(--colorBoxHeadingText);
  margin: 0px;
}

.fontSize {
  font-weight: 400;
  font-size: calc(0.2vw + 0.2vh + 0.65vmin) !important;
}

.fields {
  /* height: 30px; */
  width: 100%;
  margin-top: 4px;
  color: var(--sidebarListColor);
  background-color: var(--sidebarListBgColor);
  /* font-size: 12px !important; */
  line-height: 1.4;
}

.section_nav_section {
  padding: 0px;
}

.dropdownToggleBtn {
  height: 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.buttonStyles{
    border: 1px solid var(--activeTextColor);
    /* font-size: 10px; */
    color: var(--activeTextColor);
    border-radius: 3px;
    padding: 1px 5px;
    text-transform: uppercase;
}
</style>
  